@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0px;
  padding: 0px;
  line-height: 0.5 !important;
  @apply bg-gray-50;
}
.visualization{
}

.home-card-p{
  line-height: 26px !important;
}

.lh-modal{
  line-height: 1 !important;
}

.menu-item {
  @apply flex items-center gap-2 py-4 transition-colors duration-300 hover:text-emerald-500 focus:bg-emerald-50  focus:outline-none focus-visible:outline-none lg:px-8;
}

.menu-item-selected{
   @apply text-emerald-500; 
}

span svg{
    display: inline;
}

.table-item {
  @apply px-1 py-1 inline-block items-center  transition-colors duration-300 hover:text-emerald-500 focus:bg-emerald-50  focus:outline-none focus-visible:outline-none ;
}
